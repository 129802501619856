import React, { FC, PropsWithChildren, ReactNode } from 'react';

import Layout from '~/components/Layout';
import requireUser, { UserProps } from '~/utils/requireUser';

interface TemplateUserProps {
  collectionName?: string;
  docId?: string;
  secondary?: ReactNode;
  title?: string;
}

const TemplateUser: FC<PropsWithChildren<TemplateUserProps & UserProps>> = ({
  collectionName,
  children,
  docId,
  secondary,
  title,
}) => (
  <Layout
    collectionName={collectionName}
    docId={docId}
    kind="user"
    showNavigation
  >
    <div className="mx-auto mt-6">
      {title && <h1 className="mb-6 text-xl font-bold">{title}</h1>}
      {undefined !== secondary && (
        <>
          {secondary}
          <hr className="my-6" />
        </>
      )}

      <div> {children}</div>
    </div>
  </Layout>
);
export default requireUser(TemplateUser);
